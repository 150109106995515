import { Checkbox, Text, Group, Modal } from "@mantine/core";
import { ReactNode, useState } from "react";

import { Authenticate } from "@/sample/mutations";
import { EnableAuthenticatorApp, LoginWithOTP, SignIn } from "@/layout/login";

import UnAuthLayout from "@/layout/partials/unAuthLayout";
import Link from "next/link";

const initialLoginModalValue = {
  title: "",
  open: false,
  component: null,
};

export default function Login() {
  const [loginModal, setLoginModal] = useState<{
    open: boolean;
    title: string;
    component: ReactNode;
  }>(initialLoginModalValue);

  const closeLoginModal = () => setLoginModal(initialLoginModalValue);
  const handleEnableAuthenticator = (data: Authenticate["data"]) => {
    setLoginModal({
      title: "Secure your Account",
      open: true,
      component: (
        <EnableAuthenticatorApp
          hash={data.secret}
          email={data.email}
          url={data.url}
        />
      ),
    });
  };

  const handleLoginWithOTP = (data: Authenticate["data"]) => {
    setLoginModal({
      title: "Authentication Code",
      open: true,
      component: <LoginWithOTP email={data.email} />,
    });
  };

  function onSettled(userData) {
    const { data = null } = { ...userData };
    if (userData && data) {
      if (!data?.has_setup_2FA) handleEnableAuthenticator(data);
      else handleLoginWithOTP(data);
    }
  }

  return (
    <UnAuthLayout>
      <SignIn onSettled={onSettled} url="authenticate" className="gap-8">
        <Group position="apart" className="whitespace-nowrap">
          <section className="flex items-center gap-2 grow">
            <Checkbox id="login-checkbox" />
            <span>Remember me</span>
          </section>
          <Link href="/forgot-password" passHref>
            <Text
              component="a"
              className="font-semibold text-right cursor-pointer text-accent-90 hover:underline grow"
            >
              Forgot Password?
            </Text>
          </Link>
        </Group>
      </SignIn>

      <Modal
        title={loginModal.title}
        opened={loginModal.open}
        onClose={closeLoginModal}
        classNames={{
          inner: "items-center",
        }}
      >
        {loginModal.component}
      </Modal>
    </UnAuthLayout>
  );
}
